var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content flex f-d-c f-ai-c f-jc-c" }, [
    _c("img", { attrs: { src: require("@/assets/images/pay-success.png") } }),
    _c("span", [_vm._v("支付成功")]),
    _c("div", { on: { click: _vm.clickHandle } }, [
      _vm._v(_vm._s(_vm.btnText)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }