<template>
	<div class="content flex f-d-c f-ai-c f-jc-c">
		<img src="@/assets/images/pay-success.png"/>
		<span>支付成功</span>
		<div @click="clickHandle">{{btnText}}</div>
	</div>
</template>
<script>
import { conversationDetailByOrderId } from '@/request/api/user';

export default {
	data () {
		return {
			btnText: '进入问诊',
			type: '0'
		};
	},
	created () {
		this.type = this.$route.query.type;
		if (this.type === '0') {
			this.btnText = '进入问诊';
			this.getConversation();
		} else {
			this.btnText = '查看订单详情';
		}
	},
	methods: {
		getConversation () {
			const orderId = this.$route.query.orderId;
			conversationDetailByOrderId({ orderId }).then(data => {
				if (data && data === 'retry') {
					this.getConversation();
				} else if (data) {
					this.doctorId = data.doctorId;
					this.conversationId = data.conversationId;
				}
			});
		},
		clickHandle () {
			if (this.type === '0') {
				this.$root.replace({
					path: '/dip',
					query: {
						doctorId: this.doctorId,
						at: '2',
						conversationId: this.conversationId
					}
				});
			} else {
				this.$root.replace({
					path: 'appointment-detail',
					query: {
						id: this.$route.query.appointmentId
					}
				});
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.content {
		height: 298px;
		background: #FFFFFF;
		img {
			width: 55px;
			height: 55px;
			margin-bottom: 20px;
		}
		span {
			margin-bottom: 60px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #232830;
		}
		div {
			width: 235px;
			height: 40px;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 21px;
			font-size: 15px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 40px;
		}
	}
</style>